import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var RuleConfigurationInfo = (function (_super) {
    __extends(RuleConfigurationInfo, _super);
    function RuleConfigurationInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RuleConfigurationInfo.type = 'rule:configuration:info';
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], RuleConfigurationInfo.prototype, "title", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], RuleConfigurationInfo.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], RuleConfigurationInfo.prototype, "rules", void 0);
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], RuleConfigurationInfo.prototype, "id", void 0);
    return RuleConfigurationInfo;
}(Model));
export { RuleConfigurationInfo };
