import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var NotDistributedInvoiceImportModel = (function (_super) {
    __extends(NotDistributedInvoiceImportModel, _super);
    function NotDistributedInvoiceImportModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotDistributedInvoiceImportModel.type = 'not:distributed:invoice:import.list';
    NotDistributedInvoiceImportModel.endpoint = '/api/v1/group/center/invoiceFieldMapping/allDown/';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], NotDistributedInvoiceImportModel.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], NotDistributedInvoiceImportModel.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], NotDistributedInvoiceImportModel.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], NotDistributedInvoiceImportModel.prototype, "code", void 0);
    return NotDistributedInvoiceImportModel;
}(Model));
export { NotDistributedInvoiceImportModel };
