import { __decorate, __extends, __metadata } from "tslib";
import { Model, attribute } from 'mobx-cobweb';
var Specification = (function (_super) {
    __extends(Specification, _super);
    function Specification() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Specification.prototype.getId = function () {
        if (!this.active)
            return '';
        return this.id;
    };
    Specification.prototype.getPermissions = function () {
        var _a;
        return (_a = this.permissions) === null || _a === void 0 ? void 0 : _a.map(function (line) { return line.name; });
    };
    Specification.prototype.setPermissions = function (permissions) {
        this.permissions = permissions === null || permissions === void 0 ? void 0 : permissions.map(function (name) {
            return { name: name, auth: true };
        });
    };
    Specification.prototype.isChildern = function () {
        return true;
    };
    Specification.type = 'group.tenant.specificationsd';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Specification.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "flowPlanConfigId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Specification.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "specificationGroupId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "state", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Specification.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "code", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "icon", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "color", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Specification.prototype, "rules", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Specification.prototype, "item", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Specification.prototype, "permissions", void 0);
    return Specification;
}(Model));
export { Specification };
