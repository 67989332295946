import { __awaiter, __generator } from "tslib";
export default [
    {
        id: '@mc-budget',
        path: '/mc-budget',
        ref: '/',
        onload: function () { return import('./Budget'); },
        onbefore: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            var models;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, import('./models')];
                    case 1:
                        models = _a.sent();
                        app.collection.register(models.default);
                        return [2];
                }
            });
        }); },
    },
    {
        point: '@@layers',
        prefix: '@mc-budget',
        onload: function () { return import('./layers'); },
    },
];
