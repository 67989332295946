import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
import { computed } from 'mobx';
var Platform = (function (_super) {
    __extends(Platform, _super);
    function Platform() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Platform.prototype, "id", {
        get: function () {
            return this.sourceChannel;
        },
        enumerable: false,
        configurable: true
    });
    Platform.type = 'platform.type';
    Platform.endpoint = '/api/v1/group/center/platform/options';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Platform.prototype, "sourceChannel", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Platform.prototype, "name", void 0);
    __decorate([
        computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], Platform.prototype, "id", null);
    return Platform;
}(Model));
export { Platform };
