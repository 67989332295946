import { __awaiter, __generator } from "tslib";
export default [
    {
        id: '@mc-invoice-priceTax-separated',
        path: '/mc-invoice-priceTax-separated',
        ref: '/',
        onload: function () { return import('./ProviderContainer'); },
        onbefore: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            var modelList;
            return __generator(this, function (_a) {
                modelList = require('./models').modelList;
                app.collection.register(modelList);
                return [2];
            });
        }); },
    },
    {
        point: '@@layers',
        prefix: '@mc-invoice-priceTax-separated',
        onload: function () { return import('./layers'); },
    },
];
