import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var NotDistributedPriceTaxSeparatedModel = (function (_super) {
    __extends(NotDistributedPriceTaxSeparatedModel, _super);
    function NotDistributedPriceTaxSeparatedModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotDistributedPriceTaxSeparatedModel.type = 'not:distributed:invoice:separated.list';
    NotDistributedPriceTaxSeparatedModel.endpoint = '/api/v1/group/center/invoiceSeparated/allDown/';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], NotDistributedPriceTaxSeparatedModel.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], NotDistributedPriceTaxSeparatedModel.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], NotDistributedPriceTaxSeparatedModel.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], NotDistributedPriceTaxSeparatedModel.prototype, "code", void 0);
    return NotDistributedPriceTaxSeparatedModel;
}(Model));
export { NotDistributedPriceTaxSeparatedModel };
