import { __awaiter, __generator } from "tslib";
export default [
    {
        id: '@mc-specification',
        path: '/mc-specification',
        ref: '/',
        onload: function () { return import('./specification.view'); },
        onbefore: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            var models;
            return __generator(this, function (_a) {
                models = require('./models').models;
                app.collection.register(models);
                return [2];
            });
        }); },
    },
    {
        point: '@@layers',
        prefix: '@mc-specification',
        onload: function () { return import('./layers'); },
    },
];
