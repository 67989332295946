import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var Business = (function (_super) {
    __extends(Business, _super);
    function Business() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Business.type = 'Business.list';
    Business.endpoint = '/api/v1/group/center/permission/getTenants';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Business.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Business.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Business.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Business.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Business.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Business.prototype, "sourceCorpId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Business.prototype, "sourceChannel", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Business.prototype, "parentId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Business.prototype, "inviteMode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Business.prototype, "controlMode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Business.prototype, "isAgree", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Business.prototype, "version", void 0);
    return Business;
}(Model));
export { Business };
