import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var NotDistributedBusiness = (function (_super) {
    __extends(NotDistributedBusiness, _super);
    function NotDistributedBusiness() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotDistributedBusiness.type = 'not:distributed:custom.flow.list';
    NotDistributedBusiness.endpoint = '/api/v1/group/center/planConfig';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], NotDistributedBusiness.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], NotDistributedBusiness.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], NotDistributedBusiness.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], NotDistributedBusiness.prototype, "code", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], NotDistributedBusiness.prototype, "entityId", void 0);
    return NotDistributedBusiness;
}(Model));
export { NotDistributedBusiness };
