import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var DistributedPriceTaxSeparatedModel = (function (_super) {
    __extends(DistributedPriceTaxSeparatedModel, _super);
    function DistributedPriceTaxSeparatedModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DistributedPriceTaxSeparatedModel.type = 'distributed:invoice:separated.list';
    DistributedPriceTaxSeparatedModel.endpoint = '/api/v1/group/center/invoiceSeparated/hasDown';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], DistributedPriceTaxSeparatedModel.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DistributedPriceTaxSeparatedModel.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], DistributedPriceTaxSeparatedModel.prototype, "permissions", void 0);
    return DistributedPriceTaxSeparatedModel;
}(Model));
export { DistributedPriceTaxSeparatedModel };
