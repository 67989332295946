import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var Permissions = (function () {
    function Permissions() {
    }
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Permissions.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Permissions.prototype, "auth", void 0);
    return Permissions;
}());
export { Permissions };
var Tenant = (function (_super) {
    __extends(Tenant, _super);
    function Tenant() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Tenant.type = 'tenant.list';
    Tenant.endpoint = '/api/v1/group/center/permission/getTenants';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Tenant.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Tenant.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "sourceCorpId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "sourceChannel", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "parentId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "inviteMode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "controlMode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Tenant.prototype, "isAgree", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Tenant.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Tenant.prototype, "permissions", void 0);
    return Tenant;
}(Model));
export { Tenant };
