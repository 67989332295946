import { Tenant, Log, LogError } from '@ekuaibao/collection-definition';
import { Specification } from './Specification';
import { SpecificationGroup, SpecificationGroupBatch } from './SpecificationGroup';
export var models = [
    Specification,
    SpecificationGroup,
    SpecificationGroupBatch,
    Tenant,
    Log,
    LogError,
];
