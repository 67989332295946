import budget from './budget';
import businessObject from './business-object';
import citySettings from './city-settings';
import clusterList from './cluster-list';
import contacts from './contacts';
import customDimension from './custom-dimension';
import customFlowDistribute from './custom-flow-distribute';
import customPayment from './custom-payment';
import dimensionMap from './dimension-map';
import expenseStandard from './expense-standard';
import feetype from './feetype';
import invoiceRule from './invoice-rule';
import invoiceImportSetting from './invoice-import-setting';
import invoiceInvoicePricetaxSeparated from './invoice-invoice-pricetax-separated';
import payeeAccount from './payee-account';
import permissionDistribute from './permission-distribute';
import specification from './specification';
import tenantList from './tenant-list';
import tenantSetting from './tenant-setting';
import role from './role';
import common from './common';
var plugins = []
    .concat(budget)
    .concat(businessObject)
    .concat(citySettings)
    .concat(clusterList)
    .concat(contacts)
    .concat(customDimension)
    .concat(customFlowDistribute)
    .concat(dimensionMap)
    .concat(expenseStandard)
    .concat(feetype)
    .concat(invoiceImportSetting)
    .concat(invoiceInvoicePricetaxSeparated)
    .concat(invoiceRule)
    .concat(payeeAccount)
    .concat(permissionDistribute)
    .concat(specification)
    .concat(tenantList)
    .concat(tenantSetting)
    .concat(role)
    .concat(customPayment)
    .concat(common);
export default plugins;
