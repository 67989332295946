import { Log, LogError } from '@ekuaibao/collection-definition';
import { Tenant, Permissions } from './Tenant';
import { DistributedPriceTaxSeparatedModel } from './DistributedPriceTaxSeparatedModel';
import { NotDistributedPriceTaxSeparatedModel } from './NotDistributedPriceTaxSeparatedModel';
export { Tenant, Permissions, DistributedPriceTaxSeparatedModel, NotDistributedPriceTaxSeparatedModel, Log, LogError, };
export var modelList = [
    Tenant,
    Permissions,
    DistributedPriceTaxSeparatedModel,
    NotDistributedPriceTaxSeparatedModel,
    Log,
    LogError,
];
