import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var Department = (function (_super) {
    __extends(Department, _super);
    function Department() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.children = [];
        return _this;
    }
    Department.type = 'department.list.by.tenant';
    Department.endpoint = '/api/v1/group/center/department/tree/active';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Department.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Department.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Department.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Department.prototype, "label", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Department.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Department.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Department.prototype, "code", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Department.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Department.prototype, "sourceCorporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Department.prototype, "parentId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Department.prototype, "order", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Department.prototype, "pipeline", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Department.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Department.prototype, "updateTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Department.prototype, "children", void 0);
    return Department;
}(Model));
export { Department };
