import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var Tenant = (function (_super) {
    __extends(Tenant, _super);
    function Tenant() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Tenant.preprocess = function (data) {
        data.disabled = false;
        return data;
    };
    Tenant.type = 'tenant.table';
    Tenant.endpoint = '/api/v1/group/center/tenantInfo';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Tenant.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Tenant.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "controlMode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Tenant.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "inviteMode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Tenant.prototype, "opinion", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "parentId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "sourceChannel", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "sourceCorpId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Tenant.prototype, "updateTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Tenant.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Tenant.prototype, "disabled", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Tenant.prototype, "autoDepartment", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Tenant.prototype, "maskKeyInfo", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Tenant.prototype, "keyInfo", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Tenant.prototype, "code", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Tenant.prototype, "clusterName", void 0);
    return Tenant;
}(Model));
export { Tenant };
