import { __awaiter, __generator } from "tslib";
export default [
    {
        id: '@mc-tenant-list',
        path: '/mc-tenant-list',
        ref: '/',
        onload: function () { return import('./TenantListView'); },
        onbefore: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            var modelList;
            return __generator(this, function (_a) {
                modelList = require('./model').modelList;
                app.collection.register(modelList);
                return [2];
            });
        }); },
    },
    {
        point: '@@layers',
        prefix: '@mc-tenant-list',
        onload: function () { return import('./layers'); },
    },
];
