import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
import { Specification } from './Specification';
var SpecificationGroup = (function (_super) {
    __extends(SpecificationGroup, _super);
    function SpecificationGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SpecificationGroup.prototype.isChildern = function () {
        return true;
    };
    SpecificationGroup.prototype.isParent = function () {
        var _a;
        return !!((_a = this.specifications) === null || _a === void 0 ? void 0 : _a.length);
    };
    SpecificationGroup.prototype.isBatch = function () {
        return false;
    };
    SpecificationGroup.prototype.getChildrenIds = function () {
        var _a;
        var ids = [];
        (_a = this.specifications) === null || _a === void 0 ? void 0 : _a.forEach(function (line) {
            ids.push(line.id);
            if (line.specifications) {
                var childrenId = line.getChildrenIds();
                ids = ids.concat(childrenId);
            }
        });
        return ids;
    };
    SpecificationGroup.prototype.setPermissions = function (permissions) {
        this.permissions = permissions;
    };
    SpecificationGroup.prototype.getId = function () {
        if (!this.active)
            return '';
        return this.id;
    };
    SpecificationGroup.prototype.getPermissions = function () {
        var _a;
        return (_a = this.permissions) === null || _a === void 0 ? void 0 : _a.map(function (line) { return line.name; });
    };
    SpecificationGroup.prototype.getPermissions_2 = function () {
        var _a;
        return (_a = this.permissions) === null || _a === void 0 ? void 0 : _a.filter(function (item) {
            return item.auth == true;
        });
    };
    SpecificationGroup.type = 'group.center.specification.group';
    SpecificationGroup.endpoint = '/api/v1/group/center/form/specification';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], SpecificationGroup.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], SpecificationGroup.prototype, "locked", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], SpecificationGroup.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], SpecificationGroup.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], SpecificationGroup.prototype, "updateTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "flowPlanConfigId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "specificationGroupId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "state", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "code", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "icon", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "color", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], SpecificationGroup.prototype, "rules", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroup.prototype, "item", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], SpecificationGroup.prototype, "permissions", void 0);
    __decorate([
        attribute({ toMany: SpecificationGroup }),
        __metadata("design:type", Array)
    ], SpecificationGroup.prototype, "children", void 0);
    __decorate([
        attribute({ toMany: SpecificationGroup }),
        __metadata("design:type", Array)
    ], SpecificationGroup.prototype, "specifications", void 0);
    __decorate([
        attribute({
            toOne: SpecificationGroup,
            parse: function (_, data) { return data.specificationGroupId; },
        }),
        __metadata("design:type", SpecificationGroup)
    ], SpecificationGroup.prototype, "parent", void 0);
    return SpecificationGroup;
}(Model));
export { SpecificationGroup };
var SpecificationGroupBatch = (function (_super) {
    __extends(SpecificationGroupBatch, _super);
    function SpecificationGroupBatch() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SpecificationGroupBatch.prototype.isChildern = function () {
        return false;
    };
    SpecificationGroupBatch.type = 'group.tenant.specification';
    SpecificationGroupBatch.endpoint = '/api/v1/group/center/getAllDownData';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], SpecificationGroupBatch.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], SpecificationGroupBatch.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroupBatch.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroupBatch.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], SpecificationGroupBatch.prototype, "locked", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], SpecificationGroupBatch.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], SpecificationGroupBatch.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], SpecificationGroupBatch.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], SpecificationGroupBatch.prototype, "updateTime", void 0);
    __decorate([
        attribute({ toMany: Specification }),
        __metadata("design:type", Array)
    ], SpecificationGroupBatch.prototype, "specifications", void 0);
    return SpecificationGroupBatch;
}(Model));
export { SpecificationGroupBatch };
