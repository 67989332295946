import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
import { CustomFlowNode } from './CustomFlowNode';
var ContentModel = (function (_super) {
    __extends(ContentModel, _super);
    function ContentModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ContentModel.type = 'custom.flow.distribute';
    ContentModel.endpoint = '/api/v1/group/center/planConfig';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], ContentModel.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], ContentModel.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], ContentModel.prototype, "lock", void 0);
    __decorate([
        attribute({
            toMany: CustomFlowNode,
            parse: function (_, v) { var _a; return (_a = v.nodes) === null || _a === void 0 ? void 0 : _a.map(function (d) {
                d._groupId = v.id;
                d._u_id = v.id + ':::::' + d.id;
                return d;
            }); },
        }),
        __metadata("design:type", Array)
    ], ContentModel.prototype, "nodes", void 0);
    return ContentModel;
}(Model));
export { ContentModel };
export default ContentModel;
