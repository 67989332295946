import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
import { Node, Condition } from './Node';
var Branches = (function (_super) {
    __extends(Branches, _super);
    function Branches() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.nodeVisible = false;
        _this.addVisible = false;
        return _this;
    }
    var _a, _b;
    Branches.type = 'custom.flow.planConfig:branchesInfo';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Branches.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Branches.prototype, "type", void 0);
    __decorate([
        attribute({ toOne: function () { return Node; } }),
        __metadata("design:type", typeof (_a = typeof Node !== "undefined" && Node) === "function" ? _a : Object)
    ], Branches.prototype, "next", void 0);
    __decorate([
        attribute({ toOne: function () { return Condition; } }),
        __metadata("design:type", typeof (_b = typeof Condition !== "undefined" && Condition) === "function" ? _b : Object)
    ], Branches.prototype, "condition", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Branches.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Branches.prototype, "permissions", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Branches.prototype, "extends", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Branches.prototype, "nodeVisible", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Branches.prototype, "addVisible", void 0);
    return Branches;
}(Model));
export { Branches };
var Start = (function (_super) {
    __extends(Start, _super);
    function Start() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.nodeVisible = false;
        _this.addVisible = false;
        return _this;
    }
    var _c, _d;
    Start.type = 'custom.flow.planConfig:startInfo';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Start.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Start.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Start.prototype, "name", void 0);
    __decorate([
        attribute({ toOne: function () { return Node; } }),
        __metadata("design:type", typeof (_c = typeof Node !== "undefined" && Node) === "function" ? _c : Object)
    ], Start.prototype, "next", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Start.prototype, "label", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Start.prototype, "selection", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Start.prototype, "countersign", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "allowInsert", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "skipWhenApproverNonMatched", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "autoAgreeWhenApproverRepeated", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "autoAgreeWhenApproverSameAsSubmitter", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "allowModify", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Start.prototype, "config", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Start.prototype, "carbonCopyConfigs", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Start.prototype, "timeCount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "addCountReport", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "autoRemindPrint", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "beforeRemindPrint", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Start.prototype, "expressConfig", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "commentWhenFlowHasRisk", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "forbidBatchApproveRiskFlow", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "creditNoteAvailable", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Start.prototype, "creditInspection", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "forbidAftAddNode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "forbidShiftNode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", typeof (_d = typeof Condition !== "undefined" && Condition) === "function" ? _d : Object)
    ], Start.prototype, "condition", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Start.prototype, "ebotConfig", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Start.prototype, "permissions", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "extends", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "nodeVisible", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Start.prototype, "addVisible", void 0);
    return Start;
}(Model));
export { Start };
var Begin = (function (_super) {
    __extends(Begin, _super);
    function Begin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.nodeVisible = false;
        _this.addVisible = false;
        return _this;
    }
    Begin.type = 'custom.flow.planConfig:beginInfo';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Begin.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Begin.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Begin.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Begin.prototype, "carbonCopyConfigs", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Begin.prototype, "urgent", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Begin.prototype, "isSensitive", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Begin.prototype, "isRequired", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Begin.prototype, "hidePrintBtnDependOnRemind", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Begin.prototype, "outboundConfig", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Begin.prototype, "budgetCalcTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Begin.prototype, "permissions", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Begin.prototype, "extends", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Begin.prototype, "nodeVisible", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Begin.prototype, "addVisible", void 0);
    return Begin;
}(Model));
export { Begin };
var OrganizationData = (function (_super) {
    __extends(OrganizationData, _super);
    function OrganizationData() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OrganizationData.type = 'custom.flow.planConfig:organizationInfo';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], OrganizationData.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], OrganizationData.prototype, "owner", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], OrganizationData.prototype, "useOrg", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], OrganizationData.prototype, "editOrg", void 0);
    return OrganizationData;
}(Model));
export { OrganizationData };
var Flow = (function (_super) {
    __extends(Flow, _super);
    function Flow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Flow.type = 'custom.flow.planConfig:detailInfo';
    Flow.endpoint = '/api/v1/group/center/planConfig/getDetailInfo';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Flow.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Flow.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Flow.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Flow.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Flow.prototype, "updateTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Flow.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Flow.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Flow.prototype, "corporationId", void 0);
    __decorate([
        attribute({ toOne: Start }),
        __metadata("design:type", Start)
    ], Flow.prototype, "start", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Flow.prototype, "state", void 0);
    __decorate([
        attribute({ toOne: Begin }),
        __metadata("design:type", Begin)
    ], Flow.prototype, "begin", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Flow.prototype, "editorId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Flow.prototype, "configMessages", void 0);
    __decorate([
        attribute({ toOne: OrganizationData }),
        __metadata("design:type", OrganizationData)
    ], Flow.prototype, "organizationData", void 0);
    return Flow;
}(Model));
export { Flow };
