import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
import { Branches } from './Flow';
var Condition = (function (_super) {
    __extends(Condition, _super);
    function Condition() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Condition.prototype.toString = function () {
        switch (this.type) {
            case 'NUMBER':
                var E_SOURCE = ['TOTAL_AMOUNT', 'PAID_AMOUNT'];
                var L_SOURCE = [i18n.get('报销金额'), i18n.get('支付金额')];
                var E_OPERATOR = ['GT', 'GE', 'EQ'];
                var L_OPERATOR = ['>', i18n.get('≥'), '='];
                var n0 = L_SOURCE[E_SOURCE.indexOf(this.source)];
                var n1 = L_OPERATOR[E_OPERATOR.indexOf(this.operator)];
                var n2 = this.value;
                return i18n.get("{__k0}{__k1}{__k2} \u624D\u9700\u8981\u5BA1\u6279", { __k0: n0, __k1: n1, __k2: n2 });
            case 'BUDGET':
                return i18n.get('提交送审时占用预算超额才需要审批');
            case 'RULE':
                return i18n.get('提交送审时费用标准超标时才需要审批');
            case 'CUSTOM':
                return this.name || this.label;
            case 'NODE':
                return i18n.get('有寄单节点此节点才生效');
            default:
                return 'test';
        }
    };
    Condition.type = 'custom.flow.planConfig:conditionInfo';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Condition.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Condition.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Condition.prototype, "level", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Condition.prototype, "operator", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Condition.prototype, "source", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Condition.prototype, "value", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Condition.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Condition.prototype, "label", void 0);
    return Condition;
}(Model));
export { Condition };
var Node = (function (_super) {
    __extends(Node, _super);
    function Node() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.nodeVisible = false;
        _this.addVisible = false;
        return _this;
    }
    Node.type = 'custom.flow.planConfig:nodeInfo';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Node.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Node.prototype, "type", void 0);
    __decorate([
        attribute({ toOne: function () { return Node; } }),
        __metadata("design:type", Node)
    ], Node.prototype, "next", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Node.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Node.prototype, "label", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Node.prototype, "selection", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Node.prototype, "countersign", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "allowInsert", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "skipWhenApproverNonMatched", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "autoAgreeWhenApproverRepeated", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "autoAgreeWhenApproverSameAsSubmitter", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "allowModify", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Node.prototype, "config", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Node.prototype, "carbonCopyConfigs", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Node.prototype, "timeCount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "addCountReport", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "autoRemindPrint", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "beforeRemindPrint", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Node.prototype, "expressConfig", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "commentWhenFlowHasRisk", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "forbidBatchApproveRiskFlow", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "creditNoteAvailable", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], Node.prototype, "creditInspection", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "forbidAftAddNode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "forbidShiftNode", void 0);
    __decorate([
        attribute({ toMany: function () { return Branches; } }),
        __metadata("design:type", Array)
    ], Node.prototype, "branches", void 0);
    __decorate([
        attribute({ toOne: Condition }),
        __metadata("design:type", Condition)
    ], Node.prototype, "condition", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Node.prototype, "permissions", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "extends", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "nodeVisible", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Node.prototype, "addVisible", void 0);
    return Node;
}(Model));
export { Node };
