import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var InvoiceRule = (function (_super) {
    __extends(InvoiceRule, _super);
    function InvoiceRule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InvoiceRule.type = 'invoice.rule';
    InvoiceRule.endpoint = '/api/v1/group/center/invoice';
    InvoiceRule.enableStorage = true;
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], InvoiceRule.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], InvoiceRule.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], InvoiceRule.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], InvoiceRule.prototype, "sourceCorporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], InvoiceRule.prototype, "permissions", void 0);
    return InvoiceRule;
}(Model));
export default InvoiceRule;
