import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var Cluster = (function (_super) {
    __extends(Cluster, _super);
    function Cluster() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Cluster.type = 'cluster.table';
    Cluster.endpoint = '/api/v1/group/center/tenant/clusterInfo';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Cluster.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Cluster.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Cluster.prototype, "alias", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Cluster.prototype, "appKey", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Cluster.prototype, "appSecret", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Cluster.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Cluster.prototype, "freeCount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Cluster.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Cluster.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Cluster.prototype, "presetCount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Cluster.prototype, "tenantCount", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Cluster.prototype, "updateTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Cluster.prototype, "url", void 0);
    return Cluster;
}(Model));
export { Cluster };
