import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model, modelToRecord } from 'mobx-cobweb';
import { FilterRule } from './FilterRule';
var Filters = (function (_super) {
    __extends(Filters, _super);
    function Filters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Filters.prototype.dataOf = function () {
        return {
            type: this.type,
            targetCorpId: this.corporationId,
            condition: this.condition,
            filter: this.filter.map(function (d) { return modelToRecord(d); }),
        };
    };
    Filters.type = 'automatic:filter:rule:info';
    Filters.endpoint = '/api/v1/group/center/automaticConfig';
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Filters.prototype, "pipeline", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Filters.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Filters.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Filters.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Filters.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Filters.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Filters.prototype, "updateTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Filters.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Filters.prototype, "sourceCorporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Filters.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Filters.prototype, "condition", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Filters.prototype, "sourceId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Filters.prototype, "tenantCorpIds", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Filters.prototype, "downAll", void 0);
    __decorate([
        attribute({ toMany: FilterRule }),
        __metadata("design:type", Array)
    ], Filters.prototype, "filter", void 0);
    return Filters;
}(Model));
export { Filters };
