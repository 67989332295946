var ruleInfo = [
    {
        title: '同步规则',
        type: 'SYNC_RULE',
        rules: [
            {
                name: '覆盖替换',
                type: 'COVER_REPLACE',
                auth: true,
            },
            {
                name: '合并增加',
                type: 'MERGE_ADD',
                auth: false,
            },
        ],
    },
    {
        title: '同步方式',
        type: 'SYNC_WAY',
        rules: [
            {
                name: '手动同步',
                type: 'MANUAL_SYNC',
                auth: true,
            },
            {
                name: '自动同步',
                type: 'AUTO_SYNC',
                auth: false,
            },
        ],
    },
];
export default ruleInfo;
