import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var Entity = (function (_super) {
    __extends(Entity, _super);
    function Entity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Entity.type = 'entity.list';
    Entity.endpoint = '/api/v1/group/center/dataLink/allEntity';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Entity.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Entity.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Entity.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Entity.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Entity.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Entity.prototype, "sourceCorpId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Entity.prototype, "sourceChannel", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Entity.prototype, "parentId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Entity.prototype, "inviteMode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Entity.prototype, "controlMode", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Entity.prototype, "isAgree", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Entity.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Entity.prototype, "permissions", void 0);
    return Entity;
}(Model));
export { Entity };
