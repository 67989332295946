import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var BusinessFields = (function (_super) {
    __extends(BusinessFields, _super);
    function BusinessFields() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BusinessFields.type = 'Business.fields.list';
    BusinessFields.endpoint = '/api/v1/group/center/dataLink/getSelectFields';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], BusinessFields.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], BusinessFields.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], BusinessFields.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], BusinessFields.prototype, "label", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], BusinessFields.prototype, "source", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], BusinessFields.prototype, "optional", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], BusinessFields.prototype, "defaultValue", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], BusinessFields.prototype, "formula", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], BusinessFields.prototype, "calculation", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], BusinessFields.prototype, "entity", void 0);
    return BusinessFields;
}(Model));
export { BusinessFields };
