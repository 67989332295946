import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var Standard = (function (_super) {
    __extends(Standard, _super);
    function Standard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Standard.type = 'group.center.expense.standard';
    Standard.endpoint = '/api/v1/group/center/costContrl';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], Standard.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Standard.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], Standard.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Standard.prototype, "controlType", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], Standard.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], Standard.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], Standard.prototype, "permissions", void 0);
    return Standard;
}(Model));
export { Standard };
export default Standard;
