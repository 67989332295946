import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var DistributedBusiness = (function (_super) {
    __extends(DistributedBusiness, _super);
    function DistributedBusiness() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DistributedBusiness.type = 'distributed:business.list';
    DistributedBusiness.endpoint = '/api/v1/group/center/dataLink/hasDownSearch';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], DistributedBusiness.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], DistributedBusiness.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DistributedBusiness.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DistributedBusiness.prototype, "code", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DistributedBusiness.prototype, "entityId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], DistributedBusiness.prototype, "dataLink", void 0);
    return DistributedBusiness;
}(Model));
export { DistributedBusiness };
