import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model, isIdentifier } from 'mobx-cobweb';
import { uuid } from '@ekuaibao/helpers';
var FilterRule = (function (_super) {
    __extends(FilterRule, _super);
    function FilterRule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilterRule.preprocess = function (data) {
        if (isIdentifier(data === null || data === void 0 ? void 0 : data.id))
            return data;
        data.id = uuid();
        return data;
    };
    FilterRule.type = 'filter:rule:list';
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], FilterRule.prototype, "value", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], FilterRule.prototype, "algorithms", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], FilterRule.prototype, "valueType", void 0);
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], FilterRule.prototype, "id", void 0);
    return FilterRule;
}(Model));
export { FilterRule };
