import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var DistributedInvoiceImportModel = (function (_super) {
    __extends(DistributedInvoiceImportModel, _super);
    function DistributedInvoiceImportModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DistributedInvoiceImportModel.type = 'distributed:invoice:import.list';
    DistributedInvoiceImportModel.endpoint = '/api/v1/group/center/invoiceFieldMapping/hasDown';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], DistributedInvoiceImportModel.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DistributedInvoiceImportModel.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], DistributedInvoiceImportModel.prototype, "permissions", void 0);
    return DistributedInvoiceImportModel;
}(Model));
export { DistributedInvoiceImportModel };
