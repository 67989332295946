import { __awaiter, __decorate, __extends, __generator, __metadata } from "tslib";
import { attribute, Model, getModelCollection, modelToRecord } from 'mobx-cobweb';
var CustomFlowNode = (function (_super) {
    __extends(CustomFlowNode, _super);
    function CustomFlowNode() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.$update = function () { return __awaiter(_this, void 0, void 0, function () {
            var collection, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        collection = getModelCollection(this);
                        return [4, collection.request('', {
                                data: modelToRecord('_u_id'),
                            })];
                    case 1:
                        response = _a.sent();
                        (response === null || response === void 0 ? void 0 : response.error) ? this.revert() : this.commit();
                        return [2];
                }
            });
        }); };
        return _this;
    }
    CustomFlowNode.type = 'custom:flow:node';
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], CustomFlowNode.prototype, "id", void 0);
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], CustomFlowNode.prototype, "_u_id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], CustomFlowNode.prototype, "_groupId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], CustomFlowNode.prototype, "name", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], CustomFlowNode.prototype, "permissions", void 0);
    return CustomFlowNode;
}(Model));
export { CustomFlowNode };
