import { __decorate, __extends, __metadata } from "tslib";
import { attribute, Model } from 'mobx-cobweb';
var DimensionSelect = (function (_super) {
    __extends(DimensionSelect, _super);
    function DimensionSelect() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DimensionSelect.type = 'customDimension.distribute.item';
    DimensionSelect.endpoint = 'api/v1/group/center/dimension/items';
    __decorate([
        attribute({ isIdentifier: true }),
        __metadata("design:type", String)
    ], DimensionSelect.prototype, "id", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Boolean)
    ], DimensionSelect.prototype, "active", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DimensionSelect.prototype, "name", void 0);
    __decorate([
        attribute({ toMany: DimensionSelect }),
        __metadata("design:type", Array)
    ], DimensionSelect.prototype, "children", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DimensionSelect.prototype, "code", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DimensionSelect.prototype, "corporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DimensionSelect.prototype, "dimensionId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DimensionSelect.prototype, "nameSpell", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", String)
    ], DimensionSelect.prototype, "parentId", void 0);
    __decorate([
        attribute({
            toOne: DimensionSelect,
            parse: function (_, data) { return data.parentId; },
        }),
        __metadata("design:type", DimensionSelect)
    ], DimensionSelect.prototype, "parent", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], DimensionSelect.prototype, "version", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], DimensionSelect.prototype, "createTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], DimensionSelect.prototype, "updateTime", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], DimensionSelect.prototype, "orders", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Array)
    ], DimensionSelect.prototype, "type", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Number)
    ], DimensionSelect.prototype, "pipeline", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], DimensionSelect.prototype, "sourceCorporationId", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], DimensionSelect.prototype, "visibility", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], DimensionSelect.prototype, "payAccountIds", void 0);
    __decorate([
        attribute(),
        __metadata("design:type", Object)
    ], DimensionSelect.prototype, "sourceCorpId", void 0);
    return DimensionSelect;
}(Model));
export { DimensionSelect };
